<template>

  <div>

    <b-card>

      <h2 class="mb-2">
        Search Filter
      </h2>

      <!-- Table Top -->
      <b-row>

        <b-col
          cols="12"
          md="4"
          class="w-100 mb-2"
        >
          <flat-pickr
            v-model="filterTimestamp"
            class="form-control"
            :config="{ enableTime: true, dateFormat: 'Y-m-d H:i', mode: 'range'}"
            :placeholder="'Select Date Time'"
          />
        </b-col>

        <b-col
          cols="12"
          md="4"
          class="w-100 mb-2"
        >
          <v-select
            v-model="filterTrigger"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="filterTriggerOptions"
            :clearable="false"
            :placeholder="'Select Trigger'"
          />
        </b-col>

        <b-col
          cols="12"
          md="4"
          class="w-100 mb-2"
        >
          <v-select
            v-model="filterUserType"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="filterUserTypeOptions"
            :clearable="false"
            :placeholder="'Select User Type'"
            multiple
          />
        </b-col>

        <b-col
          cols="12"
          md="3"
        >
          <div class="d-flex align-items-center">
            <b-button
              variant="primary"
              @click="clearFilters"
            >
              <span class="mr-25 align-middle">Reset filters</span>
            </b-button>
          </div>
        </b-col>

      </b-row>

    </b-card>

    <!-- Table Container Card -->
    <b-card
      no-body
    >

      <div class="table-header m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label class="mr-1 mb-0">Show</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :value="perPage"
              :clearable="false"
              :reduce="option => option.value"
              class="per-page-selector d-inline-block ml-50 mr-1"
              @input="updatePerPage"
            />
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <label class="mr-1 mb-0">Search</label>
              <b-form-input
                v-model="searchQuery"
                placeholder="Search"
                class="w-25"
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refSmsAuditListTable"
        :items="fetchSmsAuditList"
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
      >

        <!-- Loader -->
        <template #table-busy>
          <TableSpinner />
        </template>

        <!-- Column: recipient -->
        <template #cell(recipient)="data">
          <b-link
            :to="{ name: 'admin-sms-audit-detail', params: { id: data.item.id } }"
          >{{ data.item.to }}</b-link>
        </template>

        <template #cell(timestamp)="data">
          {{ data.item.created_at | formatDate }}
        </template>

        <template #cell(user_type)="data">
          <span v-if="data.value =='Student'">{{ camperStudent(1) }}</span>
          <span v-else>{{ data.value }}</span>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-if="totalItems && perPage !== 'all'"
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>

    </b-card>
  </div>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BPagination,
  BLink, BButton,
} from 'bootstrap-vue'

import { onUnmounted } from '@vue/composition-api'
import vSelect from 'vue-select'
import moment from 'moment'
import flatPickr from 'vue-flatpickr-component'

import store from '@/store'
import storeModule from '@/views/admin/sms-audit/smsAuditStoreModule'

import useSmsAuditList from '@/views/admin/sms-audit/sms-audit-list/useSmsAuditList'
import TableSpinner from '@/views/components/table-spinner/TableSpinner.vue'
import { DEFAULT_PER_PAGE } from '@/constants/base'
import setPerPage from '@/helpers/setPerPage'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BPagination,
    BLink,
    BButton,
    flatPickr,

    vSelect,

    TableSpinner,
  },
  filters: {
    formatDate(value) {
      return value ? moment(value).format('YYYY-MM-DD HH:mm') : ''
    },
  },
  data() {
    return {
      filterTriggerOptions: [],
      filterUserTypeOptions: [],
    }
  },
  setup(props, { root }) {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-sms-audit'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const {
      fetchSmsAuditList,
      tableColumns,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      searchQuery,
      filterTimestamp,
      filterTrigger,
      filterUserType,
      sortBy,
      totalItems,
      isSortDirDesc,
      refSmsAuditListTable,

      refetchData,
      clearFilters,
    } = useSmsAuditList(root)

    return {
      fetchSmsAuditList,
      tableColumns,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      searchQuery,
      filterTimestamp,
      filterTrigger,
      filterUserType,
      sortBy,
      totalItems,
      isSortDirDesc,
      refSmsAuditListTable,

      refetchData,
      clearFilters,
    }
  },
  watch: {
    $route() {
      if (!this.$route.query.currentPage) {
        this.setFilterParams()
      }
      this.refetchData()
    },
  },
  async created() {
    this.filterUserTypeOptions = await this.$store.dispatch('app-email-audit/fetchUserType')
      .then(response => response.data.data.filter(name => name !== null))

    this.filterTriggerOptions = await this.$store.dispatch('app-email-audit/fetchTriggers')
      .then(response => response.data.data)

    this.setFilterParams()
    // Update filters
    window.onpopstate = () => {
      this.setFilterParams()
    }
  },
  methods: {
    updatePerPage(val) {
      localStorage.setItem('smsAuditPerPage', val)
      this.perPage = val
    },
    setFilterParams() {
      const query = { ...this.$route.query }
      const defaultPerPage = localStorage.getItem('smsAuditPerPage')
      this.currentPage = Number(query.currentPage) || 1
      this.perPage = setPerPage(query.perPage, defaultPerPage, DEFAULT_PER_PAGE)
      this.searchQuery = query.searchQuery || ''
      this.filterTimestamp = query.filterTimestamp || ''
      this.filterTrigger = query.filterTrigger || ''
      this.filterUserType = query.filterUserType || ''
    },
  },
}
</script>

<style lang="scss" scoped>
  .table-header {

    label {
      margin-bottom: 0;
    }
  }

  .per-page-selector {
    width: 90px;
  }

</style>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
